<template>
  <div class="box">
    <div class="title">
      <p>Instructions for users</p>
    </div>
    <div class="windows">
      <p class="title-item">1 Prepare your TEM</p>
      <p>Before uploading a plant cell TEM image, please perform preliminary processing on the image:</p>
      <p>1) Get the pixel length of the scale bar.</p>
      <p>
        Windows system: Open the software [Paint] that comes with the computer,
        put the picture in, zoom in to the pixel level,
        draw a line according to the scale of the picture,
        the pixel length of the X-axis will automatically appear in the lower left corner,
        for example: the length of the scale indicated in the picture is 133 Pixel,
        representing 600nm (6μm).
      </p>
      <div class="img">
        <img src="../../assets/scaleBar/win.png" alt="">
      </div>
    </div>
    <div class="ios">
      <p>
        MacOS system: Right-click the picture, select [Preview] in the open method,
        zoom in to the pixel level,
        and draw the frame directly according to the scale of the picture.
        The pixel length of the X-axis and Y-axis will automatically appear during the drawing process.
        For example, the length of the scale represented in the picture is 133 pixels, representing 600nm (6μm).
      </p>
      <div class="img">
        <img src="../../assets/scaleBar/ios.png" alt="">
      </div>
    </div>
    <div class="write">
      <p>
        Fill in the pixels and the corresponding scale length in the Plantorganelle hunter system.
      </p>
      <p class="img">
        <img src="../../assets/scaleBar/input.png" alt="">
      </p>
      <p>
        2) Trim the irrelevant areas in the image.
      </p>
      <p class="img-n">
        <img src="../../assets/scaleBar/00.png" alt="">
      </p>
      <p style="margin-top: 20px">
        3) Save the image as jpg or jpeg image format less than 10 MB.
      </p>
    </div>
    <div class="title-item title-results">
      2 Waiting for results
    </div>
    <p class="p">The identification process may take 1-2 minutes.
      After that, you can get the segmentation results and three morphological metrics (shape-complexity,
      electron-density and area) of every organelle through e-mail for free.</p>
    <div class="img-upload">
      <img src="../../assets/scaleBar/01.png" alt="">
    </div>
    <div class="title-item title-results">
      3 Getting your results
    </div>
    <p class="p">
      The result contains 6 parts,
      and the segmentation results and morphological metrics of organelle will be included in the folder named after the
      organelle.
      For example,
      the area and electron density parameters of a single organelle are included in Chloroplast_info.csv,
      and the individual serial number of the organelle is identified in Chloroplast_.png.
      In the shape_info folder, ShapeResultsTable.csv contains the shape information of the individual chloroplasts,
      visibilityGraphs.gpickle contains the original graph data of the organelles,
      and LabeledShapes.pn contains the individual serial numbers of the organelles.
    </p>
    <div class="folder">
      <img src="../../assets/scaleBar/02.png" alt=""/><br/>
      <img src="../../assets/scaleBar/03.png" alt=""/>
    </div>
    <div class="structure">
      <p>Result folder directory:</p>
      <p>|-Result</p>
      <p>|----Chloroplast</p>
      <p class="two">|----Chloroplast_.png (where organelle number is marked)</p>
      <p class="two">|----Chloroplast__.tif (organelle segmentation)</p>
      <p class="two">|----Chloroplast_info.csv (including area and electron-density of single organelle)</p>
      <p class="two">|----shape_info</p>
      <p class="three">|----LabeledShapes.png (where organelle number is marked equal to Chloroplast_.png)</p>
      <p class="three">|----ShapeResultsTable.csv (including shape-complexity of single organelle)</p>
      <p class="three">|----visibilityGraphs.gpickle (original graph data of organelle)</p>
      <p>|----Mitochondrion</p>
      <p class="two">|----......</p>
      <p>|----Nucleus</p>
      <p class="two">|----......</p>
      <p>|----Vacuole</p>
      <p class="two">|----......</p>
      <p>|----1.jpg</p>
      <p>|----2.jpg</p>
    </div>
    <div class="title-item title-results">
      4 Enjoy your Plant organ Hunter tour
    </div>
    <p class="p">
      You can grab some test images at this link! After opening the link through your browser,
      right-click to save the image locally.
    </p>
    <p class="p p-4">
      https://github.com/yzy0102/Plantorgan-hunter-OrgSegNet/raw/main/OrgSegNet_LocalDevlopment/test_img/2000nm460pixels.jpg
      <br/>
      https://github.com/yzy0102/Plantorgan-hunter-OrgSegNet/raw/main/OrgSegNet_LocalDevlopment/test_img/2000nm520pixels.jpg
      <br/>
      https://github.com/yzy0102/Plantorgan-hunter-OrgSegNet/raw/main/OrgSegNet_LocalDevlopment/test_img/800nm520pixels.jpg
    </p>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="scss">
.box {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  font-family: Regular;
  padding-top: 73px;

  .p {
    font-size: 18px;
  }

  .title {
    text-align: center;

    p {
      font-size: 18px;
    }
  }

  .title-item {
    color: #295F10;
    font-size: 23px;
  }

  .img {
    margin-top: 30px;
    text-align: center;
  }

  .windows {
    margin-top: 60px;
    font-size: 18px;
    line-height: 29px;

    p:nth-child(2) {
      margin-top: 12px;
    }
  }

  .ios {
    margin-top: 30px;
    font-size: 18px;
    line-height: 29px;

    p:nth-child(2) {
      margin-top: 12px;
    }
  }

  .write {
    margin-top: 80px;

    p {
      font-size: 18px;
      line-height: 29px;
    }

    .img-n {
      img {
        width: 100%;
      }
    }

    p:nth-child(3) {
      margin: 30px 0;
    }
  }

  .title-results {
    margin: 30px 0;
  }

  .img-upload {
    margin-top: 60px;

    img {
      width: 100%;
    }
  }

  .folder {
    margin-top: 10px;
  }

  .structure {
    font-size: 18px;

    p {
      line-height: 30px;
    }

    .two {
      margin-left: 30px;
    }

    .three {
      margin-left: 60px;
    }
  }

  .p-4 {
    margin-top: 20px;
    line-height: 26px;
    padding-bottom: 60px;
  }
}
</style>
